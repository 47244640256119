import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { functionalityTestsCountSelector, functionalityTestsSelector } from 'modules/uptime/selectors';
import { fetchFunctionalityTestsAction } from 'modules/uptime';
import { Button } from '@utiligize/shared/components';
import { dateFormat } from '@utiligize/shared/utils';
import { IconDetails, IconExternalLink } from '@utiligize/shared/resources';
import { DataTable } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';

const TableFunctionalityTests: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ADMIN_TEST_HISTORY;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const paginationType = PaginationType.ADMIN_FUNCTIONALITY_TESTS;

  const items = useSelector(functionalityTestsSelector);
  const count = useSelector(functionalityTestsCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchFunctionalityTestsAction(params)).then(
        (action: Shared.ReduxAction<{ functionalityTestsHash: Uptime.Root['functionalityTestsHash'] }>) =>
          Object.values(action.payload?.functionalityTestsHash || {})
      ),
    [dispatch]
  );

  const handleDetailsButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const test: string | null = event.currentTarget.getAttribute('data-test-name');
      const tenant: string | null = event.currentTarget.getAttribute('data-tenant');
      const portfolio: string | null = event.currentTarget.getAttribute('data-portfolio');
      const scenario: string | null = event.currentTarget.getAttribute('data-scenario');
      const modifier = { filters: { ...filters, test, tenant, portfolio, scenario }, offset: 0 };
      dispatch(setPaginationAction({ type: PaginationType.ADMIN_TEST_HISTORY, modifier }));
    },
    [filters, dispatch]
  );

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      isResetFiltersButtonEnabled
    >
      {items.map(item => (
        <tr key={item.test_run_timestamp}>
          <td className="text-nowrap">{item.name || '-'}</td>
          <td className="text-nowrap">{item.tenant || '-'}</td>
          <td className="text-nowrap">{item.portfolio || '-'}</td>
          <td className="text-nowrap">{item.scenario || '-'}</td>
          <td className="text-nowrap">{item.test_run_timestamp ? dateFormat(item.test_run_timestamp, 'lll') : '-'}</td>
          <td>{item.duration || '-'}</td>
          <td>{item.tags.join(', ')}</td>
          <td>{item.success ? 'Yes' : 'No'}</td>
          <td>{item.error_message || '-'}</td>
          <td>{item.dashboard_version || '-'}</td>
          <td className="text-right">
            <Button
              tooltipKey="Details"
              icon={<IconDetails />}
              data-test-name={item.name}
              data-tenant={item.tenant}
              data-portfolio={item.portfolio}
              data-scenario={item.scenario}
              onClick={handleDetailsButtonClick}
              linkProps={{ to: Routes.FunctionalityTestsHistory }}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Link to page"
              icon={<IconExternalLink />}
              data-id={item.run_id}
              linkProps={{ to: { pathname: item.path }, target: '_blank' }}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableFunctionalityTests;
