import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';
import { getLocation } from 'connected-react-router';

// ------------------------------------
// Selectors
// ------------------------------------
export const isRouteActiveSelectorFactory = (path: string, exact: boolean = true) =>
  createSelector(getLocation as any, (location: Location): boolean =>
    Boolean(matchPath(`${location.pathname}${exact ? location.hash : ''}`, { path, exact }))
  );
