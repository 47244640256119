import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { selectedWorkflowTenantSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { getVersionChartData } from 'modules/calculations';
import { DataTableTemplate, ContentContainer, ContentCard } from '@utiligize/shared/components';
import { Colors } from '@utiligize/shared/components/Chart';
import ChartVersionData, { ChartDataProps } from './ChartVersionData';
import SelectWorkflowTenant from 'components/_common/SelectWorkflowTenant';

const ViewVersionDetails: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const selectedWorkflowTenant = useSelector(selectedWorkflowTenantSelector);
  const [chartData, setChartData] = useState<ChartDataProps[] | null>(null);

  useEffect(() => {
    setChartData(null);
    if (!selectedWorkflowTenant) return;
    dispatch(getVersionChartData(selectedWorkflowTenant))
      .then(
        (action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x' | 'categories'>[] | null>) => {
          setChartData(
            action.payload?.map(chart => ({
              series: chart.series.map((i, index: number) => ({
                name: i.group || '',
                type: 'line' as any,
                data: i.data,
                color: Colors[index],
              })),
              title: chart.title,
              xAxisTitle: chart.xaxis_title,
              yAxisTitle: chart.yaxis_title,
            })) || []
          );
        }
      )
      .catch(() => setChartData([{ series: [] }, { series: [] }]));
  }, [dispatch, selectedWorkflowTenant]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption) => {
      dispatch(setLayoutAction({ selectedWorkflowTenant: option.value as string }));
    },
    [dispatch]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectWorkflowTenant
            environment="all"
            value={selectedWorkflowTenant}
            onChange={handleSelectChange}
            blurInputOnSelect
            page="version-details"
          />
        </Col>
      </Row>
      <ContentContainer>
        <ContentCard marginBottom>
          <ChartVersionData {...(chartData?.[0] || {})} />
        </ContentCard>
        <ContentCard>
          <ChartVersionData {...(chartData?.[1] || {})} />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

export default ViewVersionDetails;
