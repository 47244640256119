import React from 'react';
import { Switch, Route } from 'react-router';
import { ViewFunctionalityTests, ViewTestHistory } from 'components/FunctionalityTests';
import PageNotFound from './PageNotFound';
import { Routes } from 'constants/index';

const PageFunctionalityTests: React.FC = () => (
  <Switch>
    <Route exact path={Routes.FunctionalityTests} component={ViewFunctionalityTests} />
    <Route exact path={Routes.FunctionalityTestsHistory} component={ViewTestHistory} />
    <Route path="*" component={PageNotFound} />
  </Switch>
);

export default PageFunctionalityTests;
