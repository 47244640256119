import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { testHistoryCountSelector, testHistoryItemsSelector } from 'modules/uptime/selectors';
import { fetchTestHistoryAction } from 'modules/uptime';
import { dateFormat } from '@utiligize/shared/utils';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';
import { paginationSelectorFactory } from 'modules/layouts/selectors';

const TableTestHistory: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_TEST_HISTORY;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const items = useSelector(testHistoryItemsSelector);
  const count = useSelector(testHistoryCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTestHistoryAction(params)).then(
        (action: Shared.ReduxAction<{ testHistoryItems: Uptime.Root['testHistoryItems'] }>) =>
          action.payload?.testHistoryItems || []
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      waitForDependencies={!filters?.test}
      isDownloadCSVEnabled
    >
      {(items || []).map(item => (
        <tr key={item.test_run_timestamp}>
          <td className="text-nowrap">{item.test_run_timestamp ? dateFormat(item.test_run_timestamp, 'L LT') : '-'}</td>
          <td>{item.duration || '-'}</td>
          <td>{item.tags.join(', ')}</td>
          <td>{item.success ? 'Yes' : 'No'}</td>
          <td>{item.error_message || '-'}</td>
          <td>{item.dashboard_version || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableTestHistory;
