import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataHistoryTestOptionsAction } from 'modules/options';
import { dataHistoryTestOptionsSelectorFactory } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from '@utiligize/shared/components';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['test'] | null;
  tenant: Layouts.Filters['tenant'];
  portfolio: Layouts.Filters['portfolio'];
  scenario: Layouts.Filters['scenario'];
}

const SelectDataHistoryTest: React.FC<Props> = ({ tenant, portfolio, scenario, value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const key = `${tenant}_${portfolio}_${scenario}`;
  const dataHistoryTestOptionsSelector = useMemo(() => dataHistoryTestOptionsSelectorFactory(key), [key]);
  const options = useSelector(dataHistoryTestOptionsSelector);

  useEffect(() => {
    if (!tenant || !portfolio || !scenario || options) return;
    setIsLoading(true);
    dispatch(fetchDataHistoryTestOptionsAction({ tenant, portfolio, scenario, key })).finally(() =>
      setIsLoading(false)
    );
  }, [tenant, portfolio, scenario, key, options, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        if (value?.includes(String(option.value))) acc.selectValues.push(option);
        acc.selectOptions.push(option);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
  }, [value, options]);

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={!tenant || !portfolio || !scenario || isLoading}
      labelKey=""
      mutedTextLabelKey="Test"
      value={selectValues}
      options={selectOptions}
      {...props}
      variant="small"
      isClearable={false}
      blurInputOnSelect
    />
  );
};

export default SelectDataHistoryTest;
