import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalConfirmationMetaSelector } from 'modules/layouts/selectors';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import SharedConfirmationModal from '@utiligize/shared/components/ConfirmationModal';

const ConfirmationModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const inputMeta = useSelector(modalConfirmationMetaSelector);

  const handleCancelBtnClick = useCallback(() => {
    dispatch(setModalConfirmationMetaAction(null));
  }, [dispatch]);

  return <SharedConfirmationModal handleCancelBtnClick={handleCancelBtnClick} inputMeta={inputMeta} />;
};

export default ConfirmationModal;
