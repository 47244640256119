// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Root: '/',
  Callback: '/callback',
  StatusOverview: '/status-overview',
  TestOverview: '/test-overview',
  FunctionalityTests: '/functionality-tests',
  FunctionalityTestsHistory: '/functionality-tests/history',
  DataHistory: '/data-history',
  Ferrets: '/ferrets',
  Loadflow: '/loadflow',
  VersionDetails: '/version-details',
};
