import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers } from 'hooks';
import { resetReduxStateAction } from 'modules/uptime/actions';
import { DataTableTemplate, ContentContainer, ContentCard } from '@utiligize/shared/components';
import { SelectTenant, SelectPortfolio, SelectScenario } from 'components/_common';
import SelectTest from './SelectTest';
import ChartHistory from './ChartHistory';
import TableTestHistory from './TableTestHistory';
import { PaginationType } from 'constants/index';

const ViewTestHistory: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ADMIN_TEST_HISTORY;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });

  // reset history table for proper chart loading state
  useEffect(
    () => () => {
      dispatch(resetReduxStateAction({ testHistoryCount: 0, testHistoryItems: null }));
    },
    [dispatch]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={4}>
          <SelectTest name="test" value={filters?.test || null} onChange={handleSelectChange} />
        </Col>
        <Col xs={2}>
          <SelectTenant isClearable name="tenant" value={filters?.tenant || null} onChange={handleSelectChange} />
        </Col>
        <Col xs={2}>
          <SelectPortfolio
            isClearable
            name="portfolio"
            tenant={filters?.tenant}
            value={filters?.portfolio || null}
            onChange={handleSelectChange}
          />
        </Col>
        <Col xs={2}>
          <SelectScenario
            isClearable
            name="scenario"
            tenant={filters?.tenant}
            portfolio={filters?.portfolio}
            value={filters?.scenario || null}
            onChange={handleSelectChange}
          />
        </Col>
      </Row>
      <>
        <ContentContainer>
          <ContentCard>
            <ChartHistory />
          </ContentCard>
        </ContentContainer>

        <TableTestHistory />
      </>
    </DataTableTemplate>
  );
};

export default ViewTestHistory;
