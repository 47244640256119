import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadflowItemsSelector } from 'modules/calculations/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { fetchLoadflowAction, cancelLoadflowAction } from 'modules/calculations';
import { Button } from '@utiligize/shared/components';
import { DataTable } from 'components/_common';
import { dateFormat } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';
import { IconSlash } from '@utiligize/shared/resources';

interface Props {
  shouldFetch: boolean;
  resetCounter: () => void;
}

const TableLoadflow: React.FC<Props> = ({ shouldFetch, resetCounter }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_LOADFLOW;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const items = useSelector(loadflowItemsSelector);

  const sendRequest = useCallback(
    () => dispatch(fetchLoadflowAction()).finally(resetCounter),
    [dispatch, resetCounter]
  );

  useEffect(() => {
    if (shouldFetch) sendRequest();
  }, [shouldFetch, sendRequest]);

  const handleCancelButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const executionNameFull: string | null = event.currentTarget.getAttribute('data-execution-name-full');
      if (!executionNameFull) return;
      dispatch(cancelLoadflowAction(executionNameFull));
    },
    [dispatch]
  );

  const renderStateCell = (value: string) => (
    <td
      className={classNames('text-center', {
        'bg-success': value === 'SUCCEEDED',
        'bg-warning': value === 'CANCELLED',
        'bg-danger': value === 'FAILED',
      })}
    >
      {value}
    </td>
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={Number(items?.length)}
      sendRequest={sendRequest}
      waitForDependencies={!filters?.flow || !filters?.environment}
      maxHeight="calc(100vh - 170px)"
    >
      {(items || []).map(item => (
        <tr key={item.execution_name}>
          <td className="text-nowrap">
            <a href={item.tenant_link} target="_blank" rel="noopener noreferrer">
              {item.tenant}
            </a>
          </td>
          <td className="text-nowrap">{item.steps || '-'}</td>
          <td className="text-nowrap">{item.misc || '-'}</td>
          <td className="text-nowrap">{item.start_time ? dateFormat(item.start_time, 'YYYY-MM-DD, HH:mm:ss') : '-'}</td>
          <td className="text-nowrap">
            {item.end_time === 'Still Running'
              ? item.end_time
              : item.end_time
                ? dateFormat(item.end_time, 'YYYY-MM-DD, HH:mm:ss')
                : '-'}
          </td>
          <td className="text-nowrap">{item.total_time || '-'}</td>
          {renderStateCell(item.execution_state)}
          <td className="text-nowrap text-center">
            <a href={item.all_logs} target="_blank" rel="noopener noreferrer">
              all
            </a>
            {' | '}
            <a href={item.error_logs} target="_blank" rel="noopener noreferrer">
              errors
            </a>
          </td>
          <td>{item.error_msg || '-'}</td>
          <td className="text-right">
            {item.execution_state === 'ACTIVE' ? (
              <Button
                tooltipKey="Cancel"
                icon={<IconSlash />}
                data-execution-name-full={item.execution_name_full}
                onClick={handleCancelButtonClick}
                size="small"
                variant="primary"
              />
            ) : (
              '-'
            )}
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableLoadflow;
