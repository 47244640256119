import React, { useState, useCallback, useEffect } from 'react';
// @ts-ignore
import { Modal } from 'components/_common';

interface Props {
  inputMeta: Shared.ModalConfirmationMeta | null;
  handleCancelBtnClick: () => void;
}

const ConfirmationModal: React.FC<Props> = ({ inputMeta, handleCancelBtnClick }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [meta, setMeta] = useState<Shared.ModalConfirmationMeta | null>(null);
  const {
    title = '',
    titleKey = 'Do you want to delete',
    bodyComponent = null,
    submitButtonProps,
    onConfirm,
  } = meta || ({} as any);

  useEffect(() => {
    if (inputMeta) return setMeta(inputMeta);
    setTimeout(() => setMeta(null), 500);
  }, [inputMeta]);

  const handleSubmitBtnClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      if (!onConfirm) return;
      setIsLoading(true);
      onConfirm()
        .then(handleCancelBtnClick)
        .finally(() => setIsLoading(false));
    },
    [onConfirm, handleCancelBtnClick]
  );

  return (
    <Modal
      show={Boolean(inputMeta)}
      onHide={handleCancelBtnClick}
      title={title}
      titleKey={titleKey}
      cancelButtonProps={{
        disabled: isLoading,
        onClick: handleCancelBtnClick,
      }}
      submitButtonProps={{
        loading: isLoading,
        labelKey: submitButtonProps?.labelKey || 'Delete',
        onClick: handleSubmitBtnClick,
        variant: submitButtonProps?.variant || 'danger',
      }}
    >
      {bodyComponent}
    </Modal>
  );
};

export default ConfirmationModal;
