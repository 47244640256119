import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers } from 'hooks';
import { DataTableTemplate } from '@utiligize/shared/components';
import { SelectTenant, SelectPortfolio, SelectScenario } from 'components/_common';
import TableDataHistory from './TableDataHistory';
import SelectDataHistoryTest from './SelectDataHistoryTest';
import { PaginationType } from 'constants/index';

const ViewDataHistory: React.FC = () => {
  const type = PaginationType.ADMIN_DATA_HISTORY;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={4}>
          <SelectDataHistoryTest
            name="test"
            tenant={filters?.tenant}
            portfolio={filters?.portfolio}
            scenario={filters?.scenario}
            value={filters?.test || null}
            onChange={handleSelectChange}
          />
        </Col>
        <Col xs={2}>
          <SelectTenant name="tenant" value={filters?.tenant || null} onChange={handleSelectChange} />
        </Col>
        <Col xs={2}>
          <SelectPortfolio
            name="portfolio"
            tenant={filters?.tenant}
            value={filters?.portfolio || null}
            onChange={handleSelectChange}
          />
        </Col>
        <Col xs={2}>
          <SelectScenario
            name="scenario"
            tenant={filters?.tenant}
            portfolio={filters?.portfolio}
            value={filters?.scenario || null}
            onChange={handleSelectChange}
          />
        </Col>
      </Row>
      <TableDataHistory />
    </DataTableTemplate>
  );
};

export default ViewDataHistory;
