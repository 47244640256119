import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.options;

export const tenantsOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['tenantsOptions'] => options.tenantsOptions
);

export const workflowTenantsOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['workflowTenantsOptionsHash'] => options.workflowTenantsOptionsHash
);

export const workflowTenantsOptionsSelectorFactory = (key: string) =>
  createSelector(
    workflowTenantsOptionsHashSelector,
    (hash: Options.Root['workflowTenantsOptionsHash']): Type.SelectOption[] => hash[key]
  );

export const tagsOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['tagsOptions'] => options.tagsOptions
);

export const testsOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['testsOptions'] => options.testsOptions
);

export const portfolioOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['portfolioOptionsHash'] => options.portfolioOptionsHash
);

export const portfolioOptionsSelectorFactory = (tenant: Layouts.Filters['tenant']) =>
  createSelector(
    portfolioOptionsHashSelector,
    (portfolioOptionsHash: Options.Root['portfolioOptionsHash']): Type.SelectOption[] =>
      portfolioOptionsHash[String(tenant)]
  );

export const scenarioOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['scenarioOptionsHash'] => options.scenarioOptionsHash
);

export const scenarioOptionsSelectorFactory = (key: string) =>
  createSelector(
    scenarioOptionsHashSelector,
    (scenarioOptionsHash: Options.Root['scenarioOptionsHash']): Type.SelectOption[] => scenarioOptionsHash[key]
  );

export const loadflowFlowOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['loadflowFlowOptionsHash'] => options.loadflowFlowOptionsHash
);

export const loadflowFlowOptionsSelectorFactory = (env: Layouts.Filters['environment'] | null) =>
  createSelector(
    loadflowFlowOptionsHashSelector,
    (hash: Options.Root['loadflowFlowOptionsHash']): Type.SelectOption[] => hash[env || '']
  );

export const loadflowEnvironmentOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['loadflowEnvironmentOptions'] => options.loadflowEnvironmentOptions
);

export const loadflowStepsOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['loadflowStepsOptionsHash'] => options.loadflowStepsOptionsHash
);

export const loadflowStepsOptionsSelectorFactory = (key: string) =>
  createSelector(
    loadflowStepsOptionsHashSelector,
    (hash: Options.Root['loadflowStepsOptionsHash']): Type.SelectOption[] | null => hash?.[key] || null
  );

export const loadflowFlowTypeOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['loadflowFlowTypeOptionsHash'] => options.loadflowFlowTypeOptionsHash
);

export const loadflowFlowTypeOptionsSelectorFactory = (key: string) =>
  createSelector(
    loadflowFlowTypeOptionsHashSelector,
    (hash: Options.Root['loadflowFlowTypeOptionsHash']): Type.SelectOption[] => hash[key]
  );

export const loadflowPortfolioOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['loadflowPortfolioOptionsHash'] => options.loadflowPortfolioOptionsHash
);

export const loadflowPortfolioOptionsSelectorFactory = (key: string) =>
  createSelector(
    loadflowPortfolioOptionsHashSelector,
    (hash: Options.Root['loadflowPortfolioOptionsHash']): Type.SelectOption[] | null => hash?.[key] || null
  );

export const loadflowScenarioOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['loadflowScenarioOptionsHash'] => options.loadflowScenarioOptionsHash
);

export const loadflowScenarioOptionsSelectorFactory = (key: string) =>
  createSelector(
    loadflowScenarioOptionsHashSelector,
    (hash: Options.Root['loadflowScenarioOptionsHash']): Type.SelectOption[] | null => hash?.[key] || null
  );

export const loadflowInvestmentScenarioOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['loadflowInvestmentScenarioOptionsHash'] =>
    options.loadflowInvestmentScenarioOptionsHash
);

export const loadflowInvestmentScenarioOptionsSelectorFactory = (key: string) =>
  createSelector(
    loadflowInvestmentScenarioOptionsHashSelector,
    (hash: Options.Root['loadflowInvestmentScenarioOptionsHash']): Type.SelectOption[] | null => hash?.[key] || null
  );

export const dataHistoryTestOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['dataHistoryTestOptionsHash'] => options.dataHistoryTestOptionsHash
);

export const dataHistoryTestOptionsSelectorFactory = (key: string) =>
  createSelector(
    dataHistoryTestOptionsHashSelector,
    (hash: Options.Root['dataHistoryTestOptionsHash']): Type.SelectOption[] => hash[key]
  );
