import { createAction } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------
export const setLayoutAction = createAction<Partial<Layouts.Root>>('layouts/SET_LAYOUT_MODIFIER');

export const closeSidebarAction = createAction('layouts/CLOSE_SIDEBAR');

export const toggleSidebarAction = createAction('layouts/TOGGLE_SIDEBAR');

export const setErrorToastAction = createAction('layouts/SET_ERROR_TOAST');

export const setInfoToastAction = createAction('layouts/SET_INFO_TOAST');

export const setSuccessToastAction = createAction('layouts/SET_SUCCESS_TOAST');

export const deleteToastAction = createAction('layouts/DELETE_TOAST');

export const setPaginationAction = createAction('layouts/SET_PAGINATION');

export const setModalConfirmationMetaAction = createAction('layouts/SET_MODAL_DELETE_CONFIRMATION_META');
